var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Dependencies } from '~/core/decorator';
import { Emit, Prop } from 'vue-property-decorator';
import { ExportTemplateService } from '~/services/dataimp-service/export-template.service';
import { DataExportService } from '~/services/dataimp-service/data-export.service';
var WorkOrderExport = /** @class */ (function (_super) {
    __extends(WorkOrderExport, _super);
    function WorkOrderExport() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.model = {
            templateId: '',
        };
        _this.submitLoading = false;
        _this.AllConfigs = [];
        return _this;
    }
    WorkOrderExport.prototype.close = function () {
        this.reset();
    };
    WorkOrderExport.prototype.success = function () {
        this.close();
    };
    WorkOrderExport.prototype.refresh = function () {
        var _this = this;
        this.exportTemplateService.getExportTemplateByPrincipal(this.exportData.principalId, 'WORK_ORDER', this.loading)
            .subscribe(function (data) { return (_this.AllConfigs = data); }, function (err) { });
    };
    WorkOrderExport.prototype.submit = function () {
        var _this = this;
        if (this.model.templateId === '') {
            this.$message('请选择导出模板');
            return;
        }
        var model = {
            templateId: this.model.templateId,
            principalId: this.exportData.principalId,
            minDate: this.exportData.followTime[0],
            maxDate: this.exportData.followTime[1]
        };
        this.loading.state = true;
        this.dataExportService.exportWorkOrder(model, this.loading).subscribe(function (data) {
            _this.$message.success('正在导出工单信息,请关注任务盒子!');
            _this.close();
        }, function (err) { });
    };
    WorkOrderExport.prototype.reset = function () {
        this.model.templateId = '';
    };
    __decorate([
        Dependencies(ExportTemplateService)
    ], WorkOrderExport.prototype, "exportTemplateService", void 0);
    __decorate([
        Dependencies(DataExportService)
    ], WorkOrderExport.prototype, "dataExportService", void 0);
    __decorate([
        Prop({
            required: true
        })
    ], WorkOrderExport.prototype, "exportData", void 0);
    __decorate([
        Emit()
    ], WorkOrderExport.prototype, "close", null);
    __decorate([
        Emit()
    ], WorkOrderExport.prototype, "success", null);
    WorkOrderExport = __decorate([
        Component({
            components: {},
        })
    ], WorkOrderExport);
    return WorkOrderExport;
}(Vue));
export default WorkOrderExport;
